/* You can add global styles to this file, and also import other style files */
@font-face {
  font-family: 'Ford Antenna';
  src:
    url('./assets/fonts/ford_antenna/FordAntenna-Regular.woff2') format('woff2'),
    url('./assets/fonts/ford_antenna/FordAntenna-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

@font-face {
  font-family: 'Ford Antenna';
  src:
    url('./assets/fonts/ford_antenna/FordAntenna-Medium.woff2') format('woff2'),
    url('./assets/fonts/ford_antenna/FordAntenna-Medium.woff') format('woff');
  font-weight: 500;
  font-style: normal;
  font-display: block;
}

@font-face {
  font-family: 'Ford Antenna';
  src:
    url('./assets/fonts/ford_antenna/FordAntenna-LightItalic.woff2') format('woff2'),
    url('./assets/fonts/ford_antenna/FordAntenna-LightItalic.woff') format('woff');
  font-weight: 300;
  font-style: italic;
  font-display: block;
}

@font-face {
  font-family: 'Ford Antenna';
  src:
    url('./assets/fonts/ford_antenna/FordAntenna-Light.woff2') format('woff2'),
    url('./assets/fonts/ford_antenna/FordAntenna-Light.woff') format('woff');
  font-weight: 300;
  font-style: normal;
  font-display: block;
}

@font-face {
  font-family: 'Ford Antenna';
  src:
    url('./assets/fonts/ford_antenna/FordAntenna-Semibold.woff2') format('woff2'),
    url('./assets/fonts/ford_antenna/FordAntenna-Semibold.woff') format('woff');
  font-weight: 600;
  font-style: normal;
  font-display: block;
}

@font-face {
  font-family: 'Ford Antenna';
  src:
    url('./assets/fonts/ford_antenna/FordAntenna-RegularItalic.woff2') format('woff2'),
    url('./assets/fonts/ford_antenna/FordAntenna-RegularItalic.woff') format('woff');
    font-weight: normal;
    font-style: italic;
    font-display: block;
}

@font-face {
  font-family: 'Ford Antenna';
  src:
    url('./assets/fonts/ford_antenna/FordAntenna-SemiboldItalic.woff2') format('woff2'),
    url('./assets/fonts/ford_antenna/FordAntenna-SemiboldItalic.woff') format('woff');
  font-weight: 600;
  font-style: italic;
  font-display: block;
}

@font-face {
  font-family: 'Ford Antenna';
  src:
    url('./assets/fonts/ford_antenna/FordAntenna-MediumItalic.woff2') format('woff2'),
    url('./assets/fonts/ford_antenna/FordAntenna-MediumItalic.woff') format('woff');
  font-weight: 500;
  font-style: italic;
  font-display: block;
}

:root {
  --custom-fds-color--primary: #00095F;
}

* {
  font-family: 'Ford Antenna', arial, helvetica, sans-serif;
  letter-spacing: 0.35px;
}

.fa {
  font-family: 'Ford Antenna', arial, helvetica, sans-serif;

  &__light {
    font-weight: 300;
  }

  &__medium {
    font-weight: 500;
  }

  &__semibold {
    font-weight: 600;
  }

  &__regular {
    font-weight: normal;
  }
}

html {
    background-color: var(--fds-color--white);
}

.container {
  margin-bottom: 45px;
}

.loading {
  width: 100%;
  height: 80vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.loading-content {
  margin: 2rem 0;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

// display
.d-flex {
  display: flex;

  &.justify-content-between {
    justify-content: space-between;
  }

  &.justify-content-space-around {
    justify-content: space-around;
  }

  &.justify-content-center {
    justify-content: center;
  }

  &.align-items-center {
    align-items: center;
  }

  &.flex-direction {
    &__column {
      flex-direction: column;
    }
  }

  &.g10 {
    gap: 10px;
  }

  &.g20 {
    gap: 20px;
  }
}

.blank {
  height: 100vh;
  width: 100%;
  position: fixed;
  z-index: 9999;
  background-color: #fff;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
}
